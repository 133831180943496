import Vue from 'vue';

Vue.mixin({
  methods: {
    onScroll() {
      this.checkIsInViewport();
    },
    onResize() {
      this.setHeights();
    },
    setHeights() {
      let introHeight = document.querySelector('header').height;
      this.$store.commit('setHeaderHeight', introHeight);
      this.$store.commit('setComponentsHeightIntervals', this.computeHeights());
    },
    checkIsInViewport() {
      let y = window.scrollY;
      let intervals = this.$store.state.componentsHeightIntervals;
      let section = intervals.find((interval) => {
        return y >= interval.start && y <= interval.stop;
      });
      this.$store.commit('setActiveSectionId', section && section.component);
    },
    computeHeights() {
      let components = [
        'site-header',
        'partners',
        'projects',
        'team',
        'contacts',
      ];
      return components.reduce(function(accumulator, component, index) {
        let start = index ? accumulator[index - 1].stop + 1 : 0;
        let stop = start + document.querySelector(`.${component}`).height;
        accumulator.push({ start, stop, component });
        return accumulator;
      }, []);
    },
    getTitle(tabPath) {
      let mt = this.getLocalized(tabPath, 'mobileTitle');
      let t = this.getLocalized(tabPath, 'title');
      return (process.client && window.innerWidth < 768 && (mt || t)) || t;
    },
    getLocalized(path, property) {
      let m = this.$t(`${path}.${property}`);
      let isNotString = typeof m !== 'string';
      return (isNotString && m) || (m && !m.startsWith(path)) ? m : null;
    }
  },
});
